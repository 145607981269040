import "src/components/organisms/WordPress/SNS.css.tsx.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/WordPress/SNS.css.tsx.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VVTY/aMBC98ytGW620K9WRAwSoufTUQw9Vpf4CJxnArWNHtiGw1f73ys4Hhg3tqqt1pBAPzzPvecbjhB/m/HSi8HsCUApbS35isJF4XE8Afu6tE5sTKbRyqBwDW/MCSY6uQVQe4ZGkMbxmoLT/9cZCy32lyNZb5warAVgKg4UTWrEOs548T5KWQhoo1NqKFmBQcicOOCzOuRWWQUbvvemJCFXikUHqZzkvfm2N3quSFFpqw+DDlzD8nxU3W6EYTA1W7Ysmy/7bA5zhqg/LpQSaZDZmxnb6gCbw0zUvhDsxoMkqgkzfnXxE+fyKGfyb5KwlyctSqC2DNMm8n3Wc+K0RpTeE1LWAMDWiJA6rWnKHpE2dZbCi9RH43ukoyDwEiTWnZz+5PhIrnkL4XJsSDcl1qLSBVZ+TsU1Z+qf1E9YaXoq9ZTCvjwPLrvas48Z1+xvbUZUMpoPV6OYl1BsDbuZNXIqtIhblhkGByqGJ1GatWq0c2fBKyBODu2/aafjBlYWv3+8+guXKEotGbNY91Ion9Nu76LSOhegzT3LtnK4YpP1B8h4aFNudY7Cg9Iby6ajy2euVDxoXb9LYn/8+iYswvEUKhWTXCZle1tprsjiqZfZSyzzSsgxaRmqLhrG+OMQ8t1ruHUbJMC3bgsviIaP3QCCjh+YxQkjc3AA4XTMIITovtC3lNr1h0i6mFy2CRvRXV20mZvif7tPI/ad3cv+5wlJwsIVBVMBVCQ+VUKQRpdsxWC5W9fExhE7i2+jlnWF0450/n5Fph+y7ZOiR9Ao0fQ1o1oEuGtFwe91sgWmaDT3wwt/8Kmh66WmkwG81qhsHdqTMLwhk/SbGpzH7G4vpDRazcRbX5M7YiMWiY/H2cNfmWPTzH47upePHCAAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var backgroundDiv = 'av4ayy7';
export var contactLink = 'av4ayy9';
export var container = 'av4ayy3';
export var descriptionClass = 'av4ayy6';
export var imageContainer = 'av4ayy4';
export var lineLink = 'av4ayy8';
export var multiColumnGrid = 'av4ayy0';
export var multiColumnTileLeft = 'av4ayy1';
export var multiColumnTileRight = 'av4ayy2';
export var titleClass = 'av4ayy5';