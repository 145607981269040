import React from 'react';
import {
  wrapDiv,
  ul,
  li,
  img,
  imgDiv,
  postTitle,
  text,
  titleClass,
  titleContainer,
} from './Archivement.css';

const dataSrc = [
  {
    id: '1',
    sourceUrl: '/images/case01.jpg',
    alt: '秋田県仙北市での町おこしイベント',
    name: '秋田県仙北市での町おこしイベント',
    company: '',
    companyLink: '',
    description:
      '秋田県仙北市の市長に町おこし企画を提案。当時、ゾンビ映画が流行していたことから、都内でゾンビイベントを開催しファンを増やすとともに、仙北市の廃校を貸し切ってのゾンビイベントを企画。都内から貸切バスで数百人が参加。',
  },
  {
    id: '2',
    sourceUrl: '/images/case02.jpg',
    alt: '上履きプロジェクト',
    name: '上履きプロジェクト',
    company: '',
    companyLink: '',
    description:
      '靴を履かずに生活している子どもたちが足の裏を怪我し、感染症で命を落としてしまう。そんな子どもたちを少しでも減らすために、日本の子どもたちが履かなくなった上履きや靴を現地に送るプロジェクト。<br>実績<br>2018年6月 インドに約200足を輸送<br>2018年11月 カンボジアに約170足を届ける<br>2019年2月 カンボジアに約230足を寄付<br>2019年5月 マダガスカルに約40足を輸送<br>2019年8月 インドに750足を輸送',
  },
];

const Archivement = React.memo(() => {
  return (
    <>
      <div className={`inner ${wrapDiv}`}>
        <div className={titleContainer}>
          <h3 className={titleClass}>過去のイベント事例</h3>
        </div>
        <ul className={ul}>
          {dataSrc.map((item: any) => {
            return (
              <>
                <li key={item.id} className={li}>
                  <div className={imgDiv}>
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={
                          item.sourceUrl.replace(/\.[^/.]+$/, '') + '.webp'
                        }
                      />
                      <img
                        className={img}
                        src={item.sourceUrl}
                        alt={item.alt}
                      />
                    </picture>
                  </div>
                  {/* <div><a className={copmany} href={item.companyLink} target={'_blank'} > {item.company}</a></div> */}
                  <div className={postTitle}>{item.name}</div>
                  <div
                    className={text}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </li>
              </>
            );
          })}
        </ul>
      </div>
    </>
  );
});

export default Archivement;
