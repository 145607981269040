import "src/components/organisms/WordPress/Archivement.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/WordPress/Archivement.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE31U25KjIBB9z1dQs5WqmQcsjZoLednP2LctlDYhg2BBZ5PZrfz7Fkgm6pjxQaEvh+PpbpLf2Sk7FNcyJf8WhLTcHqSmaDpGcgvt/mGrDKJpGdkmu+C4LZJ7bhZyO+MkSqMZsaA4yj/gszsuhNSHHnKzWs4gZkkxRVxNEHnljDpjQAxIqV8paJCRMg2gaLl2jbEt65eKI/x6pWW6fPPuixR4ZCRL++gjyMMRH3tTnaBG2khkpDYaudQjQnkgJKTrFP9gpFFw9Wmns0PZfFCfAhp7B3XILXp32AlpoY7CmMun+WJ5x4h/z0hSJNuo/sFHrab6FIFOwKm4k+7xI18oTjnURp1bvX9asGlxIo+RfgMm5bBvpkmN0Uid/Au+yOtoRLgiFVAby/vTz1qAVVLDCHgdgB916jtHano39ZaLsYJWFvg7I+FDuVLf/MeIUj7VddPrOkc7GC/x7HWajvK2cyrkSTnF333FLyP+9+PTt3qWfOmEHWMVNMZCQP7sw5eX8ajcOQ3mJtqtABvh8+5KnFFSkB9peEYNJbWvEa2Uqd/3T6ZzQIwPJAmH3RY/WxCSE1dbAE24FuTVFzS21ma97a5vISsZ30pP7yBCbsPofD56F1XzrsHoaXMfvjhlxRxmETGHs1Zsln3c7T+Bv9aAPwUAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var copmany = '_1j1g4x55';
export var img = '_1j1g4x52';
export var imgDiv = '_1j1g4x51';
export var li = '_1j1g4x54';
export var p = '_1j1g4x5a';
export var postTitle = '_1j1g4x57';
export var text = '_1j1g4x56';
export var titleClass = '_1j1g4x59';
export var titleContainer = '_1j1g4x58';
export var ul = '_1j1g4x53';
export var wrapDiv = '_1j1g4x50';