import "src/components/organisms/WordPress/TextImage02.css.tsx.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/WordPress/TextImage02.css.tsx.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE71UwY6bMBC95ytGW1VqpTUCloTEvVTdtsf+QmXDkHgXbIrNhqTKv1cYQzDJXqscIr08z7z3ZibB76gzojr/CeHvCuAocnOgsAs/flkBVKwjDoniMKy7AWz2QpJG7A+GAmuNmqElFjMwF7ou2YlCUaJ9+9JqI4oTyZQ0KA0FXbMMCUdzRJQ9o2eSY8NqClL13z2YqbKtJNn3aBQ2WM06cmWMqnqBQeJ+UW/YFKU6UjiIPO/rXlbB6DOyPm0bzrTQFNaD2TMRMseOQuTxY8t3zYyqKZA4WNtOM1ZiWbeN/QzDa6wHHPK7Yp6b0Ku+ns9mfLGsoPgLZoYUwlDIeiUzsFZaGKEkhQyl6X+aVd8MiShpiBZnpJAEsUvSggWrRHmi8PANOdPwC1t8eISsbbR4w4l1dHKSMHQjUw2FD8/f4+f4h7cMQpZCIuGlyl7vDdL1rlmeC7mnEE4QV10v0aJcNTk2hKvOM5MuzcS+mVHmZpD5f0Rtl6KiaVdt13GU4wrPnu7sU86y132jWpmTMdifaf+xmqbhMq5V2Ro7lPGS02DtrUuyuGO70qnb6MmgO+Tp2N4zOTsbu7JfK8wFA501iBKYzOFTJeR4AOlmW3efraPA/9/x0nlyTW+nMKZ2mVeIXYW5oad7xMQnLmfrcTe3srb3eOn78j3e9pYXTaEv1uBugZ0r4KLcDkd/nevT2s31srr8A9GUh1rVBQAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var backgroundDiv = '_1xtimzq9';
export var container = '_1xtimzq2';
export var contentClass = '_1xtimzq7';
export var descriptionClass = '_1xtimzq8';
export var image = '_1xtimzq5';
export var imageContainer = '_1xtimzq4';
export var multiColumnGrid = '_1xtimzq0';
export var multiColumnTile = '_1xtimzq1';
export var textContainer = '_1xtimzq3';
export var titleClass = '_1xtimzq6';