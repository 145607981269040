import React from 'react'

import { pageWrap } from './Layout.css'
import SNS from './SNS';
import SocialSection from './SocialSection';

interface Props {
  children?: React.ReactNode;
}

const Layout = React.memo(({ children }: Props) => {

  return (
    <>
      {/* <Seo seo={seo} uri={uri}/> */}
      <div className={pageWrap}>
        <>{children}
        </>
      </div>
      <SNS />
    </>
  )
});

export default Layout;
