import React, { useContext } from 'react';
import { GatsbyContext } from '../context/context';

import CategoryProducts from '../components/organisms/WordPress/CategoryProducts';
import { OgOneColumnHero } from '../components/organisms/Heroes/OgOneColumnHero';
import { Helmet } from 'react-helmet-async';
import Member from '../components/organisms/WordPress/Member';
import TextImage01 from '../components/organisms/WordPress/TextImage01';
import TextImage02 from '../components/organisms/WordPress/TextImage02';
import AutoSlider from '../components/organisms/WordPress/AutoSlider';
import SNS from '../components/organisms/WordPress/SNS';
import Title from '../components/organisms/WordPress/Title';
import { useGetWordpressPostByCategorySettings } from '../hooks/useGetWordpressPostByCategorySettings';
import SEO from '../utils/seo';
import Layout from '../components/organisms/WordPress/Layout';
import Archivement from '../components/organisms/WordPress/Archivement';

export default function Index() {
  const { loading: postLoading, data: postLists } =
    useGetWordpressPostByCategorySettings({
      slug: 'event',
      count: 3,
      nextId: '',
    });
  const { isSidebarOpen } = useContext(GatsbyContext);

  if (postLoading) {
    return <div></div>;
  }

  const postItems = postLists.categories.edges[0].node.posts.edges;

  return (
    <>
      <SEO title={'Hyper Gravity'} />
      <Layout>
        <Title
          Tag="h2"
          title="What's Hyper Gravity"
          subTitle="Hyper Gravityとは？"
        />
        <div
          className="inner"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontSize: '18px',
              paddingBottom: '108px',
              lineHeight: '180%',
            }}
          >
            Hyper
            Gravity（ハイグラ）は早慶上智発のインカレビジネスサークルです。
            <br />
            <br />
            現役のビジネスパーソンのもとで、社会での即戦力となる学生を目指し、
            <br />
            講義・ワークやイベント企画を通じてビジネスを体得していきます。
            <br />
            <br />
            <span
              style={{
                fontSize: '18px',
                paddingBottom: '108px',
                lineHeight: '180%',
                fontWeight: 700,
                marginTop: '12px',
              }}
            >
              1.ビジネスを実践の場で学ぶ
            </span>
            <br />
            実際に収益を生む活動を通じて、学生自身がお金を稼ぎながらビジネスを学ぶ
            <br />
            <span
              style={{
                fontSize: '18px',
                paddingBottom: '108px',
                lineHeight: '180%',
                fontWeight: 700,
                marginTop: '12px',
              }}
            >
              2.海外での活動を通じて知見を広める
            </span>
            <br />
            海外での社会貢献活動やインターンシップを通じ、国際的な知見を広める
            {/* 一般社団法人CRYINGTIGER JAPANとは <br className="pc" />
          『稼ぐ・遊ぶ・貢献する』をバランスよく、
          <br className="pc" />
          楽しくこなす「かっこいい」を追求する団体です。
          <br />
          将来起業を目指す20～30代の社会人によって構成され、
          <br className="pc" />
          さまざまな活動を行っています。 本プロジェクト、 Live with Smile
          <br className="pc" />
          ーSaving your feetーは 『貢献する』の一環。 過去には、
          <br className="pc" />
          地域活性化を目的とし、秋田でイベントを開催したり
          <br className="pc" />
          フットサルイベントの参加費で 貧困地域へポリオワクチンを送ったりと
          <br className="pc" />
          さまざまな活動に取り組んでいます。 */}
          </p>
        </div>
        <Title Tag="h2" title="Curriculum" subTitle="カリキュラム" />
        <TextImage02
          flexDirection={'row'}
          textAlign={'right'}
          right={0}
          imageSrc={'/images/top_meeting.jpg'}
          imageAlt={'現役経営者による講義・イベント企画'}
          title="1年生"
          content="現役経営者による講義・イベント企画"
          description="【目標：ビジネスの基礎を体感する】自分のアイデアでお金を生み出す（売上を上げる）ことを通じて、ビジネスで求められるクリエイティビティを身につける。
          （アルバイトやインターンによる時給制では得られない経験）"
        />
        <TextImage02
          flexDirection={'row-reverse'}
          textAlign={'left'}
          left={0}
          imageSrc={'/images/uwabaki.jpg'}
          imageAlt={'ゲスト講義'}
          title="2年生"
          content="アジアでのボランティア活動等"
          description="【目標：経験を広げ人間性を磨く】当社団法人の前代表がカンボジアで法人を経営している背景から、カンボジアを中心としたアジア諸国でのボランティア活動を通じて人間性を磨く。"
        />
        <TextImage02
          flexDirection={'row'}
          textAlign={'right'}
          right={0}
          imageSrc={'/images/top_event.jpg'}
          imageAlt={'イベント'}
          title="3年生"
          content="先進国でのビジネス・インターン等"
          description="【目標：世界でのビジネス経験を積む】当社団法人の代表の友人が米国で法人を経営している背景から、米国を中心とした先進国でのビジネス・インターン経験の場を用意し、世界でのビジネス経験を積む。"
        />
        <div className="ohanamiContainer inner" style={{ marginTop: '-84px' }}>
          <p className="ohanami">
            全ての段階において、現役経営者からのサポート・アドバイスを行います。
          </p>
        </div>
        <Archivement />
        {/* <div className="ohanamiContainer">
          <h3 className="ohanami">起業に興味がある仲間を募集中！！</h3>
        </div> */}
        {/* <div
          className="inner"
          style={{
            maxWidth: '640px',
            display: 'flex',
          }}
        >
          <img src="/images/ohanami.jpg" />
        </div> */}
        {/* <div
          className="inner"
          style={{
            maxWidth: '640px',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '32px',
            paddingBottom: '108px',
          }}
        >
          <p
            style={{
              fontSize: '18px',
              lineHeight: '180%',
            }}
          >
            4月13日(土)、〇〇公園にて桜の美しさを皆で楽しむお花見会を開催します！
            <br />
            春の訪れを感じながら、親しい仲間たちと素敵な時間を過ごしましょう。
            <br />
            午前10時に公園のメインゲートに集合です。
            <br />
            お弁当や飲み物の持ち込みは大歓迎！
            <br />
            一緒に楽しい思い出を作りましょう。
            <br />
            参加を希望される方は、以下LINEからご連絡ください。
            <br />
            皆様のご参加を心よりお待ちしております！
          </p>
          <a style={{ width: '120px' }} href="https://lin.ee/Mg76dn1">
            <img
              src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
              alt="友だち追加"
              height="36"
            />
          </a>
        </div> */}
      </Layout>
    </>
  );
}
